<template>
    <!--begin::Aside-->
    <div class="flex-row-auto offcanvas-mobile w-300px w-xl-300px" id="kt_profile_aside">
        <!--begin::Nav Panel Widget 4-->
        <div class="card card-custom gutter-b">
            <!--begin::Body-->
            <div class="card-body pt-4">
                <!--begin::User-->
                <div class="d-flex align-items-center pt-5" v-if="coachList.length > 0">
                    <div class="symbol symbol-60 symbol-xxl-60 mr-5 align-self-start align-self-xxl-center">
                        <img :src="coachList[0].user.profileimage" alt="image" />
                    </div>
                    <div>
                        <span class="font-weight-bolder font-size-h5 text-dark text-hover-primary">{{
                            coachList[0].user.firstname }}
                            {{ coachList[0].user.lastname }}</span>
                    </div>
                </div>
                <div class="d-flex align-items-center pt-5" v-else>
                    <div class="symbol symbol-60 symbol-xxl-60 mr-5 align-self-start align-self-xxl-center">
                        <img :src="currentUser.profileimage" alt="image" />
                    </div>
                    <div>
                        <span class="font-weight-bolder font-size-h5 text-dark text-hover-primary">{{
                            currentUser.firstname }}</span>
                    </div>
                </div>
                <!--end::User-->
                <!--begin::Contact-->
                <div class="py-9" v-if="coachList.length > 0">
                    <div class="d-flex align-items-center justify-content-between mb-2"
                        v-if="currentUser.isAdmin || currentUser.isEmployee">
                        <span class="font-weight-bold mr-2">Type:</span>
                        <span class="text-dark text-hover-primary">Admin</span>
                    </div>
                    <div class="d-flex align-items-center justify-content-between mb-2" v-if="currentUser.isCoach == true">
                        <span class="font-weight-bold mr-2">Current Status:</span>
                        <span v-if="coachList[0].isTest" class="text-danger text-hover-primary">Offline</span>
                        <span v-else class="text-success text-hover-primary">Online</span>
                    </div>
                    <div class="d-flex align-items-center justify-content-between mb-2">
                        <span class="font-weight-bold mr-2">Email:</span>
                        <span class="text-dark text-hover-primary">{{ coachList[0].email }}</span>
                    </div>
                </div>
                <div class="py-9" v-else>
                    <div class="d-flex align-items-center justify-content-between mb-2"
                        v-if="currentUser.isAdmin || currentUser.isEmployee">
                        <span class="font-weight-bold mr-2">Type:</span>
                        <span class="text-dark text-hover-primary">Admin</span>
                    </div>
                    <div class="d-flex align-items-center justify-content-between mb-2">
                        <span class="font-weight-bold mr-2">Email:</span>
                        <span class="text-dark text-hover-primary">{{ currentUser.email }}</span>
                    </div>
                </div>
                <!--end::Contact-->
                <!--begin::Nav-->
                <div class="navi navi-bold navi-hover navi-active navi-link-rounded" role="tablist">
                    <div class="mb-2 font-size-h6 font-weight-bold pb-0 tabeven"
                        v-if="currentUser.isAdmin || currentUser.isEmployee || currentUser.isCoach" role="tab">
                        <button v-b-toggle.accordion-1 class="tabbutton">
                            Home
                        </button>
                        <button v-b-toggle.accordion-1>
                            <span class="when-opened">
                                <i class="fa fa-chevron-down colorchevron"></i>
                            </span>
                            <span class="when-closed">
                                <i class="fa fa-chevron-up"></i>
                            </span>
                        </button>
                    </div>
                    <b-collapse id="accordion-1" accordion="my-accordion" class="mt-2" role="tabpanel" v-model="tabone">
                        <div class="navi-item mb-2"
                            v-if="currentUser.isAdmin || currentUser.isEmployee || currentUser.isCoach">
                            <router-link :to="{ name: 'dashboard' }" class="navi-link py-4"
                                :class="pageActive == 'dashboard' ? 'active' : ''">
                                <span class="navi-icon mr-2">
                                    <span class="navi-icon">
                                        <i class="fa fa-home"></i>
                                    </span>
                                </span>
                                <span class="navi-text font-size-lg">Dashboard</span>
                            </router-link>
                        </div>
                        <div class="navi-item mb-2"
                            v-if="currentUser.isAdmin || currentUser.isEmployee || currentUser.isCoach">
                            <router-link :to="{ name: 'calendar' }" class="navi-link py-4"
                                :class="pageActive == 'calendar' ? 'active' : ''">
                                <span class="navi-icon mr-2">
                                    <span class="navi-icon">
                                        <i class="fa fa-calendar"></i>
                                    </span>
                                </span>
                                <span class="navi-text font-size-lg">Calendar</span>
                            </router-link>
                        </div>
                        <div class="navi-item mb-2"
                            v-if="currentUser.isAdmin || currentUser.isEmployee || currentUser.isCoach">
                            <a href="https://chat.google.com/room/AAAAS62DOpA?cls=7" target="_blank" class="navi-link py-4"
                                :class="pageActive == 'chat' ? 'active' : ''">
                                <span class="navi-icon mr-2">
                                    <span class="navi-icon">
                                        <i class="fa fa-comments"></i>
                                    </span>
                                </span>
                                <span class="navi-text font-size-lg">Google Chat</span>
                            </a>
                        </div>
                    </b-collapse>
                    <div class="mt-7 mb-2 font-size-h6 font-weight-bold pb-0 tabeven"
                        v-if="currentUser.isAdmin || currentUser.isEmployee">
                        <button v-b-toggle.accordion-2 class="tabbutton">Admin</button>
                        <button v-b-toggle.accordion-2>
                            <span class="when-opened">
                                <i class="fa fa-chevron-down colorchevron"></i>
                            </span>
                            <span class="when-closed">
                                <i class="fa fa-chevron-up"></i>
                            </span>
                        </button>
                    </div>
                    <b-collapse id="accordion-2" accordion="my-accordion" class="mt-2" role="tabpanel" v-model="tabtwo">
                        <div class="navi-item mb-2" v-if="currentUser.isAdmin">
                            <router-link :to="{ name: 'blog' }" class="navi-link py-4"
                                :class="pageActive == 'adminblog' ? 'active' : ''">
                                <span class="navi-icon mr-2">
                                    <span class="navi-icon">
                                        <i class="fa fa-blog"></i>
                                    </span>
                                </span>
                                <span class="navi-text font-size-lg">Blog</span>
                            </router-link>
                        </div>
                        <div class="navi-item mb-2" v-if="currentUser.isAdmin || currentUser.isEmployee">
                            <router-link :to="{ name: 'adminfeed' }" class="navi-link py-4"
                                :class="pageActive == 'adminfeed' ? 'active' : ''">
                                <span class="navi-icon mr-2">
                                    <span class="navi-icon">
                                        <i class="fa fa-photo-video"></i>
                                    </span>
                                </span>
                                <span class="navi-text font-size-lg">Feed</span>
                            </router-link>
                        </div>
                        <div class="navi-item mb-2" v-if="currentUser.isAdmin || currentUser.isEmployee">
                            <router-link :to="{ name: 'admincoaches' }" class="navi-link py-4"
                                :class="pageActive == 'admincoaches' ? 'active' : ''">
                                <span class="navi-icon mr-2">
                                    <span class="navi-icon">
                                        <i class="fa fa-chalkboard-teacher"></i>
                                    </span>
                                </span>
                                <span class="navi-text font-size-lg">Coaches</span>
                            </router-link>
                        </div>
                        <div class="navi-item mb-2" v-if="currentUser.isAdmin">
                            <router-link :to="{ name: 'adminusers' }" class="navi-link py-4"
                                :class="pageActive == 'adminusers' ? 'active' : ''">
                                <span class="navi-icon mr-2">
                                    <span class="navi-icon">
                                        <i class="fa fa-school"></i>
                                    </span>
                                </span>
                                <span class="navi-text font-size-lg">Users</span>
                            </router-link>
                        </div>
                        <div class="navi-item mb-2" v-if="currentUser.isAdmin">
                            <router-link :to="{ name: 'corporate' }" class="navi-link py-4"
                                :class="pageActive == 'admincompanies' ? 'active' : ''">
                                <span class="navi-icon mr-2">
                                    <span class="navi-icon">
                                        <i class="fa fa-building"></i>
                                    </span>
                                </span>
                                <span class="navi-text font-size-lg">Companies</span>
                            </router-link>
                        </div>
                        <div class="navi-item mb-2" v-if="currentUser.isAdmin">
                            <router-link :to="{ name: 'sendnotifications' }" class="navi-link py-4"
                                :class="pageActive == 'adminnotifications' ? 'active' : ''">
                                <span class="navi-icon mr-2">
                                    <span class="navi-icon">
                                        <i class="fa fa-bell"></i>
                                    </span>
                                </span>
                                <span class="navi-text font-size-lg">Notifications</span>
                            </router-link>
                        </div>
                    </b-collapse>
                    <div class="mt-7 mb-2 font-size-h6 font-weight-bold pb-0 tabeven">
                        <button v-b-toggle.accordion-3 class="tabbutton">Community</button>
                        <button v-b-toggle.accordion-3>
                            <span class="when-opened">
                                <i class="fa fa-chevron-down colorchevron"></i>
                            </span>
                            <span class="when-closed">
                                <i class="fa fa-chevron-up"></i>
                            </span>
                        </button>
                    </div>
                    <b-collapse id="accordion-3" accordion="my-accordion" class="mt-2" role="tabpanel" v-model="tabthree">
                        <div class="navi-item mb-2">
                            <router-link :to="{ name: 'coaches' }" class="navi-link py-4"
                                :class="pageActive == 'coaches' ? 'active' : ''">
                                <span class="navi-icon mr-2">
                                    <span class="navi-icon">
                                        <i class="fa fa-chalkboard-teacher"></i>
                                    </span>
                                </span>
                                <span class="navi-text font-size-lg">Coaches</span>
                            </router-link>
                        </div>
                    </b-collapse>
                    <div class="mt-7 mb-2 font-size-h6 font-weight-bold pb-0 tabeven">
                        <button v-b-toggle.accordion-4 class="tabbutton">Livestream</button>
                        <button v-b-toggle.accordion-4>
                            <span class="when-opened">
                                <i class="fa fa-chevron-down colorchevron"></i>
                            </span>
                            <span class="when-closed">
                                <i class="fa fa-chevron-up"></i>
                            </span>
                        </button>
                    </div>
                    <b-collapse id="accordion-4" accordion="my-accordion" class="mt-2" role="tabpanel" v-model="tabfour">
                        <div class="navi-item mb-2" v-if="currentUser.isCoach">
                            <router-link :to="{ name: 'livestreamtest' }" class="navi-link py-4"
                                :class="pageActive == 'videotest' ? 'active' : ''">
                                <span class="navi-icon mr-2">
                                    <span class="navi-icon">
                                        <i class="fa fa-file-video"></i>
                                    </span>
                                </span>
                                <span class="navi-text font-size-lg">Video Test</span>
                            </router-link>
                        </div>
                        <div class="navi-item mb-2" v-if="currentUser.isCoach">
                            <router-link :to="{ name: 'livestream' }" class="navi-link py-4"
                                :class="pageActive == 'startlivestream' ? 'active' : ''">
                                <span class="navi-icon mr-2">
                                    <span class="navi-icon">
                                        <i class="fa fa-video"></i>
                                    </span>
                                </span>
                                <span class="navi-text font-size-lg">Start Live Stream</span>
                            </router-link>
                        </div>
                        <div class="navi-item mb-2" v-if="currentUser.isCoach">
                            <router-link :to="{ name: 'bestpractices' }" class="navi-link py-4"
                                :class="pageActive == 'bestpracticies' ? 'active' : ''">
                                <span class="navi-icon mr-2">
                                    <span class="navi-icon">
                                        <i class="fa fa-exclamation-triangle"></i>
                                    </span>
                                </span>
                                <span class="navi-text font-size-lg">Best Practices</span>
                            </router-link>
                        </div>
                        <div class="navi-item mb-2">
                            <router-link :to="{ name: 'livestreamingnow' }" class="navi-link py-4"
                                :class="pageActive == 'livestreamnow' ? 'active' : ''">
                                <span class="navi-icon mr-2">
                                    <span class="navi-icon">
                                        <i class="fa fa-broadcast-tower"></i>
                                    </span>
                                </span>
                                <span class="navi-text font-size-lg">Live Streaming Now</span>
                            </router-link>
                        </div>
                        <div class="navi-item mb-2" v-if="currentUser.isAdmin || currentUser.isEmployee">
                            <router-link :to="{ name: 'livestreamingtest' }" class="navi-link py-4"
                                :class="pageActive == 'livestreamtest' ? 'active' : ''">
                                <span class="navi-icon mr-2">
                                    <span class="navi-icon">
                                        <i class="fa fa-stream"></i>
                                    </span>
                                </span>
                                <span class="navi-text font-size-lg">Live Streaming Test</span>
                            </router-link>
                        </div>
                    </b-collapse>
                    <div class="mt-7 mb-2 font-size-h6 font-weight-bold pb-0 tabeven">
                        <button v-b-toggle.accordion-5 class="tabbutton">Settings</button>
                        <button v-b-toggle.accordion-5>
                            <span class="when-opened">
                                <i class="fa fa-chevron-down colorchevron"></i>
                            </span>
                            <span class="when-closed">
                                <i class="fa fa-chevron-up"></i>
                            </span>
                        </button>
                    </div>
                    <b-collapse id="accordion-5" accordion="my-accordion" class="mt-2" role="tabpanel" v-model="tabfive">
                        <div class="navi-item mb-2" v-if="currentUser.isCoach">
                            <router-link :to="{
                                name: 'coachdetail',
                                params: {
                                    coachId: coachList[0].coach_list[0].coach_list_id,
                                },
                            }" class="navi-link py-4" :class="pageActive == 'profile' ? 'active' : ''">
                                <span class="navi-icon mr-2">
                                    <span class="navi-icon">
                                        <i class="fa fa-user-circle"></i>
                                    </span>
                                </span>
                                <span class="navi-text font-size-lg">Profile</span>
                            </router-link>
                        </div>
                        <div class="navi-item mb-2" v-if="currentUser.isCoach">
                            <router-link :to="{ name: 'personal' }" class="navi-link py-4"
                                :class="pageActive == 'contactdetails' ? 'active' : ''">
                                <span class="navi-icon mr-2">
                                    <span class="navi-icon">
                                        <i class="fa fa-info"></i>
                                    </span>
                                </span>
                                <span class="navi-text font-size-lg">Contact Details</span>
                            </router-link>
                        </div>
                        <div class="navi-item mb-2">
                            <router-link :to="{ name: 'password' }" class="navi-link py-4"
                                :class="pageActive == 'changepassword' ? 'active' : ''">
                                <span class="navi-icon mr-2">
                                    <span class="navi-icon">
                                        <i class="fa fa-lock"></i>
                                    </span>
                                </span>
                                <span class="navi-text font-size-lg">Change Password</span>
                            </router-link>
                        </div>
                        <div class="navi-item mb-2">
                            <a href="https://forms.gle/x7Yxyhtzuhnmn6iN9" target="_blank" class="navi-link py-4"
                                :class="pageActive == 'report' ? 'active' : ''">
                                <span class="navi-icon mr-2">
                                    <span class="navi-icon">
                                        <i class="fa fa-flag"></i>
                                    </span>
                                </span>
                                <span class="navi-text font-size-lg">Report</span>
                            </a>
                        </div>
                    </b-collapse>
                    <div class="navi-item mt-7 mb-2 text-center bg-ingomublue p-3 pointer" @click.prevent="onLogout">
                        <span class="navi-text text-white font-size-lg">Logout</span>
                    </div>
                </div>
                <!--end::Nav-->
            </div>
            <!--end::Body-->
        </div>
        <!--end::Nav Panel Widget 4-->
    </div>
    <!--end::Aside-->
</template>

<script>
import { mapGetters } from "vuex";
import { LOGOUT } from "@/core/services/store/auth.module";
import * as fb from '../../../api/firebase.js';

export default {
    name: 'userNav',
    props: {
        activePage: {
            type: String,
            default: ''
        },
        activeTab: {
            type: String,
            default: '1'
        }
    },
    data() {
        return {
            pageActive: this.activePage,
            currentTab: this.activeTab,
            currentUser: [],
            tabone: false,
            tabtwo: false,
            tabthree: false,
            tabfour: false,
            tabfive: false
        }
    },
    async mounted() {
        this.$store.dispatch("updateUserDetails");
        this.$store.dispatch("getCurrentCoachDetail")
        let user = await fb.firebase.getCurrentUser()
        fb.usersCollection.where("user_id", "==", user.uid).onSnapshot((snapshot) => {
            if (snapshot.docs.length > 0) {
                snapshot.docs.map((doc) => {
                    let user = doc.data()
                    this.currentUser = user
                })
            }
        }, (error) => { })
        switch (this.currentTab) {
            case "1":
                this.tabone = true
                this.tabtwo = false
                this.tabthree = false
                this.tabfour = false
                this.tabfive = false
                break
            case "2":
                this.tabone = false
                this.tabtwo = true
                this.tabthree = false
                this.tabfour = false
                this.tabfive = false
                break
            case "3":
                this.tabone = false
                this.tabtwo = false
                this.tabthree = true
                this.tabfour = false
                this.tabfive = false
                break
            case "4":
                this.tabone = false
                this.tabtwo = false
                this.tabthree = false
                this.tabfour = true
                this.tabfive = false
                break
            case "5":
                this.tabone = false
                this.tabtwo = false
                this.tabthree = false
                this.tabfour = false
                this.tabfive = true
                break
            default:
                this.tabone = true
                this.tabtwo = false
                this.tabthree = false
                this.tabfour = false
                this.tabfive = false
                break
        }
    },
    methods: {
        onLogout() {
            this.$store
                .dispatch(LOGOUT)
                .then(() => this.$router.push({ name: "login" }));
        },
    },
    computed: {
        ...mapGetters({
            coachList: "userProgramCoachDetail"
        })
    }
}
</script>

<style scoped>
.collapsed>.when-opened,
:not(.collapsed)>.when-closed {
    display: none;
}

.colorchevron {
    color: #3B5998 !important;
}

.tabbutton {
    width: 100%;
    text-align: left;
}


.symbol.symbol-xxl-60>img {
    width: 100%;
    max-width: 60px;
    height: 60px;
}

.tabeven {
    display: flex;
    justify-content: space-between;
}

.pointer {
    cursor: pointer;
}

@media (min-width: 1200px) {
    .w-xl-300px {
        width: 300px !important;
    }
}
</style>